.send-bericht-modal-dialog {
    padding: 0;
    height: 85%;
    position: fixed;
    bottom: 0;
    left: unset;
    top: 55%;
}

.send-bericht-modal-dialog__title {
    margin: 0 2rem;
    padding: 2rem 0;
    border-bottom: 1px solid #eee;
    position: fixed;
    top: 0;
    height: 12rem;
    left: 0;
    right: 0;
}

.send-bericht-modal-dialog__title li {
    font-size: smaller;
    margin-bottom: 4px;
}

.normal {
    font-weight: normal;
}

.bold {
    font-weight: bold;
    padding: 0 0 0.5rem 0;
}

.title {
    border-bottom: 1px solid #eee;
}

.open-vraag:first-child {
    border-bottom: 1px solid #eee;
}

.welkomstTekst {
    margin: 2rem 0;
}

.send-bericht-modal-dialog__content {
    margin: 0 2rem;
    overflow-y: scroll;
    position: fixed;
    top: 12rem;
    bottom: 16rem;
    left: 0;
    right: 0;
}

.send-bericht-modal-dialog__content.full {
    bottom: 10.5rem;
}

.send-bericht-modal-dialog__buttons {
    position: fixed;
    padding: 0;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 13rem;
}

.message-separating-date-stamp {
    display: inline-block;
    margin: 1rem auto;
    width: 100%;
    text-align: center;
    font-weight: bolder;
    color: #6B6B6B;
}

.send-message-text-area {
    outline: none !important;
    position: absolute;
    border: none;
    border-top: 1px solid #eee;
    padding: 1rem 10rem 1rem 2rem;
    height: 10.5rem;
}

.send-message-text-area:focus {
    box-shadow: none !important;
    background: #f1f2f4;
}

.send-button {
    background-color: transparent !important;
    border: none;
    margin: 1rem 0;
    padding: 0;
    width: 28px;
    height: 28px;
    cursor: pointer;
    position: absolute;
    right: 2rem;
}

.symbioseteam-hulpvraag-content-title {
    font-weight: 500;
    margin: 1rem 0;
}

.symbioseteam-hulpvraag-footer {
    position: fixed;
    bottom: 10.5rem;
    left: 0;
    right: 0;
    margin: 0 2rem;
    height: 5.5rem;
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
}

.close-hulpvraag {
    margin: 0 2rem;
    cursor: pointer;
}

.close-hulpvraag > i {
    margin: 0 1rem;
}

.pill--actie-status {
    border: 1px solid #ffc516;
    background-color: #ffc516;
}

.popper {
    margin-right: 10px !important;
    margin-top: 30px !important;
    z-index: 999;
}
