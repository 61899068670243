.foto-thumbnail {
    height: 100%;
    position: static;
    object-fit: contain;
}

.foto-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    margin-right: 5px;
    height: 110px;
    width: 110px;
}
