.left {
    float: left;
}

.right {
    float: right;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.equal-height-container {
    display: flex;
}

.equal-height-col {
    flex: 1;
}

.fill-container {
    height: 100%;
}

.detail-symbiose-materiaal {
    margin-right: 2rem;
}

.detail-symbiose-tabnavigation .vl-layout--wide {
    min-width: 0;
    padding-left: 0;
    padding-top: 3rem;
}

.vl-s-h3 {
    font-weight: 500;
    margin-bottom: 2rem;
}

.clickable {
    cursor: pointer;
}

.aanbod-tile {
    color: #A3CC00;
}

.vraag-tile {
    color: #2B979D;
}

.startpagina-tile {
    text-align: center;
}

.elements-startpagina-tile {
    margin: auto;
}

.nieuw-button {
    float: right;
    margin-top: 10px;
}

.show-all-link-container {
    text-align: right;
    margin-top: 10px;
}

.hulp-button {
    float: right;
    margin-top: 10px;
    clear: both;
    background-color: #A3CC00;
    color: black;
    border: #A3CC00;
    border-radius: 30px;
    height: 4rem;
}

.hulp-button:hover {
    background-color: #A3CC00;
    border: #A3CC00;
    color: black;
}

.bericht-button {
    width: 100%;
    float: none;
}

.info-tile-header {
    word-break: break-word;
}

.vl-info-tile__content {
    margin-top: 0;
}

.content {
    min-height: calc(100vh - 186px);
}

.symbiose-logo {
    height: 80px;
    width: auto;
}

.symbiose-status {
    float: right;
    margin-top: 10px;
}

.symbiose-frame {
    border: solid 1px #989898;
    padding: 2rem 3rem;
}

.symbiose-frameless {
    border: none;
}

.symbiose-frame > .symbiose-button {
    display: block;
    width: 100%;
    margin: 1rem auto;
}

.symbiose-frame > p, .symbiose-frameless > p {
    font-size: smaller;
}

.bericht-tile {
    cursor: pointer;
    padding: 1rem 0 1rem 0;
}

.bericht-tile:hover {
    background-color: #EFEFEF;
}

.symbiose-create-modal-tekst {
    align-content: center;
}

.symbiose-overzicht-onderwerp-tekst {
    font-size: 18px;
    color: #06c;
}

.symbiose-overzicht-onderneming-naam {
    font-size: 16px;
}

.symbiose-overzicht-click {
    cursor: pointer;
    padding: 1rem 0 1rem 0;
}

.symbiose-overzicht-click:hover {
    background-color: #EFEFEF;
}

.public-title {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.public-text {
    font-weight: normal;
    font-size: large;
}

.private-title {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}

.private-text {
    font-weight: normal;
    font-size: large;
}

.section-clear-margins {
    margin: unset;
}

.button-bericht-pill {
    margin-bottom: 4px;
    margin-left: 20px;
}

.search-form {
    background-color: whitesmoke;
    padding: 2rem;
}

.algemene-voorwaarden {
    width: 780px;
    height: 400px;
    overflow-x: scroll;
}

.detail-buttons {
    margin-top: 10px;
    text-align: right;
}

.detail-buttons .vl-button {
    width: 25rem;
}

.detail-buttons .vl-button-group .vl-button {
    margin-right: 0;
}

.detail-buttons.detail-buttons--wide .vl-button {
    width: 100%;
}

.detail-buttons button.hulp-button {
    width: initial;
    margin-right: 0;
}

.buttons-overview {
    width: 260px;
}

.detail-pagina-titel-image {
    margin-top: 8px
}

.detail-header-titel-image {
    float: left;
    margin-right: 5rem;
}

.detail-header-titel-text {
    float: left;
    width: 100%;
}

.detail-symbiose-button {
    width: 100%;
}

.detail-symbiose-actie-gevraagd {
    border: 1px solid #ccc;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.detail-symbiose-actie-gevraagd-badge {
    background-color: #e69b1f;
    margin-right: 10px;
    border: 0;
}

.detail-symbiose-actie-gevraagd-badge-content {
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.detail-symbiose-actie-gevraagd-label.block {
    float: left;
}

.detail-symbiose-actie-gevraagd-label {
    font-weight: bold;
}

.detail-symbiose-actie-gevraagd-wraper {
    text-align: left;
    margin-bottom: 10px;
}

.detail-symbiose-actie-gevraagd-element {
    float: left;
}

.welkompagina-buttons {
    width: 49%;
}

.welkompagina-titel {
    color: gray;
    font-weight: bold;
}

.welkompagina-bullet-points {
    list-style-type: disc;
    margin-left: 50px;
}

.welkomstpagina-zoeken {
    list-style-type: none;
}

.welkomstpagina-zoeken > a {
    min-height: 102px;
}

.welkompagina-hoofdtoegangsbeheerder {
    font-weight: bold;
}

.alert-nieuwe-gebruikers {
    margin-top: 8px;
}

.pill--actie-status-open {
    border: 1px solid #ffc516;
    background-color: #ffc516;
}

.pill--actie-status-gesloten {
    border: 1px solid #989898;
}

.alert-zoeken {
    padding-top: 10px;
}

.hulp-nodig-space {
    margin-right: 10px;
}

.relevantie-accordion > div {
    background-color: white;
    padding-top: 0;
}

.js-vl-accordion--open .vl-accordion__content {
    max-height: 100%;
}

.register-alert .vl-alert__title {
    line-height: 1.5;
    font-size: 2.4rem;
    margin-bottom: 2rem;
}

.operator-logo {
    margin-top: -2.2rem;
    width: 20px;
    height: 20px;
    fill: green;
    background: url(operator.svg) no-repeat;
    background-size: 20px 20px;
    border-radius: 20px;
}

.hulp-button-logo {
    display: inline-block;
    margin: 0 1rem -0.5rem 0;
}

.relevantie-icon {
    margin-right: 1rem;
}

div.cover-foto {
    text-align: center;
}

.overzicht-tegel-right {
    padding-top: 1rem;
}

.vertical-align-center {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

img.cover-foto {
    vertical-align: middle;
    max-width: 150px;
    max-height: 150px;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.icon-value {
    margin-left: unset;
    margin-right: 1rem;
    width: 16px;
    text-align: center;
}

.vl-content-header--large .vl-content-header__wrapper > .vl-layout, .vl-content-header--small .vl-content-header__wrapper > .vl-layout {
    justify-content: unset;
    padding-top: 0;
}

.vl-content-header--large .vl-content-header__content > *, .vl-content-header--small .vl-content-header__content > * {
    margin-top: 6rem;
}

.welkomstpagina-aanmelden-backgrond > .vl-functional-header__actions {
    background-color: #d1d3d6;
}

.welkomstpagina-funtional-header-aanmelden > .vl-layout--wide {
    padding-right: 0;
}

.vl-functional-header--has-actions:before {
    background-color: #d1d3d6;
}

.welkomstpagina-funtional-header-aanmelden {
    background-color: transparent;
}

.uitleg-relevantie-berekening {
    font-style: italic;
    color: #666;
    font-weight: 400;
    font-size: 1.4rem;
}

.categorie-parent {
    font-weight: bold;
}

.categorie-hover {
    background-color: #ccc;
}

.categorie-modal-content {
    height: 80%;
    overflow: scroll;
}

.categorie-modal {
    height: 90%;
    overflow: hidden;
}

.categorie-search {
    background-color: #e8ebee;
    padding: 10px;
}

.categorie-search-logo {
    display: inline-block;
    padding-right: 10px;
    padding-top: 5px;
    width: 3%;
}

.categorie-search-input {
    display: inline-block;
    width: 97%;
}

.standaardcategorie {
    padding-left: 40px;
}

.subcategorie {
    padding-left: 50px;
}

.categorie-list {
    padding-bottom: 5%;
}

.checklist-afval-grondstof-url {
    font-size: 1.6rem;
}

.gevaarlijke-stoffen-melding {
    color: #db3434;
    font-size: 1.4rem;
}

.bericht-inhoud-cell {
    width: 450px;
}

.button-search-component {
    width: 220px;
}

.empty-overview-opsomming {
    list-style-type: disc;
    margin-left: 50px;
}
