.text-tile > h1 {
    font-weight: 500;
}

.niet-vrijgegeven {
    color: #989898;
}

.text-tile > pre {
    white-space: pre-wrap;
    word-break: break-word;
}

.text-tile > p {
    font-size: smaller;
    float: right;
    margin: 0 2rem 0 0;
}

.sent-text-tile {
    border: solid 1px #989898;
    border-radius: 30px 0 30px 30px;
    float: right;
}

.received-text-tile {
    border-radius: 0 30px 30px 30px;
    float: left;
    background-color: #EDEDED;
}

.symbiose-text-tile {
    border-radius: 0 30px 30px 30px;
    float: left;
    background-color: #A3CC00;
}

.systeem-text-tile {
    width: 100%;
    clear: both;
}

.systeem-text-tile > pre {
    text-align: center;
    color: #989898;
    font-weight: 500;
}

.text-tile {
    display: block;
    padding: 1rem 1rem 1rem 2rem;
    margin: 1rem 0;
    width: 90%;
}

